// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-article-template-jsx": () => import("./../../src/components/templates/article-template.jsx" /* webpackChunkName: "component---src-components-templates-article-template-jsx" */),
  "component---src-components-templates-blog-expert-template-jsx": () => import("./../../src/components/templates/blog-expert-template.jsx" /* webpackChunkName: "component---src-components-templates-blog-expert-template-jsx" */),
  "component---src-components-templates-legal-template-jsx": () => import("./../../src/components/templates/legal-template.jsx" /* webpackChunkName: "component---src-components-templates-legal-template-jsx" */),
  "component---src-components-templates-product-detail-template-jsx": () => import("./../../src/components/templates/product-detail-template.jsx" /* webpackChunkName: "component---src-components-templates-product-detail-template-jsx" */),
  "component---src-components-templates-product-template-jsx": () => import("./../../src/components/templates/product-template.jsx" /* webpackChunkName: "component---src-components-templates-product-template-jsx" */),
  "component---src-components-templates-qualify-template-jsx": () => import("./../../src/components/templates/qualify-template.jsx" /* webpackChunkName: "component---src-components-templates-qualify-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-buscador-jsx": () => import("./../../src/pages/buscador.jsx" /* webpackChunkName: "component---src-pages-buscador-jsx" */),
  "component---src-pages-contactanos-jsx": () => import("./../../src/pages/contactanos.jsx" /* webpackChunkName: "component---src-pages-contactanos-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-registro-confirmacion-jsx": () => import("./../../src/pages/registro/confirmacion.jsx" /* webpackChunkName: "component---src-pages-registro-confirmacion-jsx" */),
  "component---src-pages-registro-jsx": () => import("./../../src/pages/registro.jsx" /* webpackChunkName: "component---src-pages-registro-jsx" */),
  "component---src-pages-registro-paso-1-jsx": () => import("./../../src/pages/registro/paso-1.jsx" /* webpackChunkName: "component---src-pages-registro-paso-1-jsx" */),
  "component---src-pages-registro-paso-2-jsx": () => import("./../../src/pages/registro/paso-2.jsx" /* webpackChunkName: "component---src-pages-registro-paso-2-jsx" */),
  "component---src-pages-registro-paso-3-jsx": () => import("./../../src/pages/registro/paso-3.jsx" /* webpackChunkName: "component---src-pages-registro-paso-3-jsx" */),
  "component---src-pages-registro-paso-4-jsx": () => import("./../../src/pages/registro/paso-4.jsx" /* webpackChunkName: "component---src-pages-registro-paso-4-jsx" */),
  "component---src-pages-responsabilidad-social-jsx": () => import("./../../src/pages/responsabilidad-social.jsx" /* webpackChunkName: "component---src-pages-responsabilidad-social-jsx" */)
}

